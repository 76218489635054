<template>
  <div class="container" v-if="!loading">
     <div class="circle-text" v-for="(value, key) in periodoAulasLancadas" :key="key">
       <p class="periodo_text">{{ key[1] }}º Período</p>
       <span class="circle" :style="{backgroundColor: getColor(key), fontSize: '17px'}">{{ value }}</span>
     </div>
     <div class="vertical-hr"></div>
     <div class="circle-text">
       <p class="presenca_text">Dias letivos no mês:</p>
       <span class="circle" style="background-color: #096bb8; font-size: 17px;">{{ diasLetivoMensal }}</span>
     </div>
     <div class="circle-text">
       <p class="presenca_text">Presenças lançadas pelo professor:</p>
       <span class="circle" style="background-color: #2196f3; font-size: 17px;">{{ aulas_lancadas }}</span>
     </div>
  </div>
 </template>
 
 <script>
 import { ref, onMounted } from 'vue';
 import axios from 'axios';
 import { Presenca } from "@/class/presenca";
 
 export default {
  name: "PeriodStats",
  props: {
     tabelaDias: { type: Array, default: () => [] },
     turma_id: null,
     disciplina_id: null,
     aulas_lancadas: null,
  },
  setup(props) {
     const periodoAulasLancadas = ref(null);
     const diasLetivoMensal = ref(0);
     const loading = ref(true);
 
     const getColor = (key) => {
       const colors = {
         p1: '#FFC107',
         p2: '#6A0DAD',
         p3: '#f90d0d',
         p4: '#1d8d07',
       };
       return colors[key] || '#FFC107';
     };
 
     const getData = async () => {
       if (props.turma_id) {
         try {
           const resp = await Presenca.presencasLancadasPorPeriodo(props.turma_id, props.disciplina_id);
           if (resp && resp.data && resp.data.presencasLancadasPorPeriodo) {
             periodoAulasLancadas.value = resp.data.presencasLancadasPorPeriodo;
           }
         } catch (err) {
           console.error(err);
         }
       } else {
         console.error("turma_id is null or undefined.");
       }
     };
 
     const getDiasLetivos = async () => {
       if (!props.tabelaDias || props.tabelaDias.length === 0 || !props.tabelaDias[0]) {
         console.error("getDiasLetivos: this.tabelaDias is null or empty.");
         return;
       }
       try {
         const res = await axios.get(`/diasLetivosMensal/${props.tabelaDias[0].ano}/${parseInt(props.tabelaDias[0].mes)}/${props.tabelaDias[0].calendario_letivo_id}`);
         if (res && res.data) {
           diasLetivoMensal.value = res.data;
         }
       } catch (err) {
         console.error("getDiasLetivos: error occurred: " + err);
       }
     };
 
     onMounted(async () => {
       try {
         await getData();
         await getDiasLetivos();
       } catch (ex) {
         console.error(ex);
       } finally {
         loading.value = false;
       }
     });
 
     return { periodoAulasLancadas, diasLetivoMensal, loading, getColor };
  },
 };
 </script>
 
 <style scoped>
 .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
 }
 .vertical-hr {
  border-left: 1px solid #000;
  height: 35px;
 }
 .periodo_text, .presenca_text {
  padding-top: 0px;
 }
 .circle-text {
  display: flex;
  align-items: center;
  text-align: center;
 }
 .circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 10px;
  background-color: #FFC107;
  padding: 0px;
  font-weight: 800;
  color: #FFE;
  padding-top: 3px;
 }
 </style>
 